
import { getDeatil } from './../api'
import editableCell from './../../basicInformation/components/distributorManagement/components/editableCell'
export default {
  components: {
    editableCell,
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/agentVerificationActivities",
          name: "agentVerificationActivities",
          title: "代理商市场活动"
        },
        {
          path: "/agentVerificationActivities/addOredit",
          name: "addOredit",
          title: "活动新增"
        }
      ],
      pageLoadFlag: false,
      detilInfo:{},
      columns: [
        {
          title: "物料组",
          dataIndex: "type",
          width: 400,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: "预计销售额",
          dataIndex: "num",
          width: 400,
          scopedSlots: { customRender: 'num' },
        },
       
      ],
      selectedRowKeys: [],
      tableData: [], // 物料组数据
      allNum:Number(0),
      allTotal:Number(0),
      fileList:[],
      previewVisible:false,
    };
  },


  mounted() {
  
  },
  
  methods:{
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },

    handleChange({file}) {
      let fileList = [...this.fileList];
      this.fileList = fileList;
    },

    handleCancel() {
      this.previewVisible = false;
    },
    // 复制行数据
    copy( num ) {
      if(num == '1') {
        if(this.selectedRowKeys.length > 1) {
          this.$message.warning('只能一条数据进行复制!')
        } else {
          let aa = {}
          this.tableData.forEach((item,index) =>{
            this.selectedRowKeys.forEach(i=>{
              if(index == i ) {
                aa = this.tableData[index]
              }
            })
          })
          this.tableData.push(aa)
        }
      } 
    },

    //删除行数据
    delLine(num) {
      if(num == 'one') {
        if(this.selectedRowKeys.length <= 0 ){
          this.$message.warning('请至少选择一项')
        } else {
          this.tableData = this.tableData.filter((item,index) => !this.selectedRowKeys.includes(index))
          this.selectedRowKeys = []
        }
      } 
    },
    // 勾选数据
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    
    // 添加行
    addline(num) {
      if(num == 'one') {
        this.tableData.push(
          {
              type:'',
              num:0,
              price:0,
              total:0,
              message:'',
            
          }
        )
      }   
    },
    // 更改数量
    changeNum(num) {
      if(num == 'one') {
        this.allNum = 0
        this.allTotal= 0
        this.tableData.forEach(item => {
          item.total = item.num *  item.price
          this.allNum += Number(item.num )
          this.allTotal +=Number( item.price)
        });
      }
    },
    // 更改价格
    changePrice(num) {
      if(num == 'one') {
        this.allNum = 0
        this.allTotal= 0
        this.tableData.forEach(item => {
          item.total = item.num *  item.price
          this.allNum += Number(item.num )
          this.allTotal +=Number( item.price)
        });
      }
    },
    // 提交
    confim() {
    }
  }
};